import React from 'react'
import Head from 'next/head'

interface Props {
  title?: string
  image?: string
}

const OpenGraph = ({ title, image }: Props) => {
  return (
    <Head>
      {title && <meta property="og:title" content={title} />}
      {image && <meta property="og:image" content={image} />}
    </Head>
  )
}

export default OpenGraph
